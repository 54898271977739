.ModalContents {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WinLoseText {
  margin-top: 0;
  margin-bottom: 48px;
  color: transparent;
  background: linear-gradient(to right, #f8c626, #fff8c9, #c08f2e, #fff8c9);
  background-clip: text;
}

.WinLoseSubtext {
  margin: 0 60px 72px 60px;
  font-size: 24px;
  line-height: 32px;
}

.ModalButton {
  margin-left: 16px;
  color: black;
  background-color: #d3d3d3;
  &:hover {
    background-color: #a9a9a9;
  }
}

$mobile-breakpoint: 900px;

.ScreenContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    justify-content: start;
    height: auto;
    width: auto;
    padding-top: 10%;
    padding-bottom: 50%;
  }
}

.TopRightIconRow {
  position: absolute;
  top: 20px;
  right: 1.5%;
  display: flex;
  flex-direction: row;
}

.TopRightIcon {
  height: 50px;
  width: 50px;
  color: #f8c626;
  padding: 0;
  &:hover {
    cursor: pointer;
    color: #c08f2e;
  }
}

.TopLeftContainer {
  position: absolute;
  top: 32px;
  left: 2.5%;

  .TopLeftText {
    font-family: InterVariable;
    font-size: 20px;
    text-align: left;

    &.bold {
      font-weight: bold;
    }

    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 10px;
    }
  }
}

.SidePanel {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    min-height: 183px;
    margin: 0 20px 20px 20px;
    flex-direction: row;
    align-items: flex-end;

    > div {
      display: flex;

      &:first-of-type {
        flex-direction: column;
      }

      &:last-of-type {
        flex-direction: row;
      }
    }
  }
}

.FullWidthSpacedRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
  }
}

.DockOuterContainer {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.DockInnerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.IconButtonRow {
  display: flex;
  flex-direction: row;
}

.GoldGradientButton {
  border: 1px solid;
  &:not(:disabled) {
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      #f8c626,
      #fff8c9,
      #c08f2e,
      #fff8c9
    );
    &:hover {
      background-color: #282828;
    }
  }
}

.IconButtonIcon {
  width: 100%;
  height: 100%;
}

.ShuffleIcon {
  height: 60%;
  width: 75%;
}

.RotateIcon {
  height: 65%;
  width: 85%;
}

.TrashIcon {
  height: 75%;
  width: 55%;
}

.ToastButton {
  height: 30px;
  width: 30px;
  padding: 0;
  border-radius: 10px;
  &:hover {
    background-color: #282828;
  }
}

.padding16 {
  padding: 16px;
}

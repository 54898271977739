.GridCell {
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    #f8c626,
    #fff8c9,
    #c08f2e,
    #fff8c9
  );
}

.ClickableCell:hover {
  cursor: pointer;
  background-color: #7fad8c;
}

.SelectedCell {
  background-color: #7fad8c;
}

.UnclickableCell {
  background-color: #005b19;
}

$mobile-breakpoint: 900px;

.DarkBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.HideBackground {
  display: none;
}

.Dialog {
  background-color: black;
  border: 2px solid #4caf50;

  @media only screen and (max-width: $mobile-breakpoint) {
    min-height: 30%;
  }
}

.FullSizeDialog {
  height: 70%;
  width: 50%;

  @media only screen and (max-width: $mobile-breakpoint) {
    width: 90%;
  }
}

.CloseIcon {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  color: #f8c626;
  padding: 0;
  &:hover {
    cursor: pointer;
    color: #c08f2e;
  }
}

.DialogTitle {
  text-align: left;
  margin-top: 12px;
  margin-bottom: 12px;
}

.DialogContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
}

.DialogInnerContainer {
  flex: 1;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
  background-color: #404040;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #f8c626;
  &:hover {
    background-color: #c08f2e;
  }
}

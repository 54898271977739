$mobile-breakpoint: 900px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gunplay";
  src: url("./fonts/gunplay_rg.otf") format("opentype");
}

@font-face {
  font-family: "InterVariable";
  src: url("./fonts/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

h1 {
  font-family: Gunplay;
  font-size: 100px;
  text-transform: uppercase;

  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 60px;
  }
}
h2 {
  font-family: Gunplay;
  font-size: 58px;
  text-transform: uppercase;

  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 30px;
  }
}
h3 {
  font-family: Gunplay;
  font-size: 32px;
  text-transform: uppercase;

  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 20px;
  }
}
h4 {
  font-family: InterVariable;
  font-size: 24px;
}
h5 {
  font-family: InterVariable;
  font-size: 20px;
}
p {
  font-family: InterVariable;
}

a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: #f8c626;
  }
}

button {
  font-family: InterVariable;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 20px;
  background-color: #000000;
  color: #ffffff;
  border: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    color: #404040;
  }
}

.Byline {
  left: 2.5%;
  width: 95%;
  font-size: 10px;
  padding-bottom: 16px;

  &.BylineIntro {
    position: absolute;
    bottom: 16px;
  }

  @media only screen and (min-width: 900px) {
    position: absolute;
    bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
  }
}

.BestExperience {
  padding-bottom: 16px;

  @media only screen and (min-width: 900px) {
    display: none;
  }
}

$mobile-breakpoint: 900px;

.ScreenContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    justify-content: start;
    height: auto;
    width: auto;
    padding-top: 20%;
    padding-bottom: 50%;
  }
}

.TopRightIconRow {
  position: absolute;
  top: 20px;
  right: 1.5%;
  display: flex;
  flex-direction: row;
}

.TopRightIcon {
  height: 50px;
  width: 50px;
  color: #f8c626;
  padding: 0;
  &:hover {
    cursor: pointer;
    color: #c08f2e;
  }
}

.Header {
  margin: -40px 0 0;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin: 0;
  }
}

.SubHeader {
  font-size: 24px;

  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
  }
}

.FullWidthSpacedRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.WTFButton {
  position: absolute;
  top: 32px;
  left: 2.5%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    #f8c626,
    #fff8c9,
    #c08f2e,
    #fff8c9
  );
  &:hover {
    background-color: #282828;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    top: 10px;
  }
}

$mobile-breakpoint: 900px;

.GameGrid {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TitleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Title {
  font-weight: bold;
}

.PrivilegeButton {
  font-size: 16px;
  border: 1px solid;
  padding: 10px;
  margin-bottom: 3px;
  &:not(:disabled) {
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      #f8c626,
      #fff8c9,
      #c08f2e,
      #fff8c9
    );
    &:hover {
      background-color: #282828;
    }
  }
}

@keyframes x {
  from {
    margin-left: 300%;
    width: 100%;
  }

  50% {
    margin-left: 150%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}
@keyframes xa {
  to {
    margin-left: 0%;
  }
}
@keyframes y {
  to {
    top: -100px;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes RightToLeft {
  0.0000% {
    -webkit-transform: translate(770px, -11px) rotate(0deg);
  }
  4.0681% {
    -webkit-transform: translate(735px, -29.3px) rotate(-5deg);
  }
  7.3487% {
    -webkit-transform: translate(700px, -44.1px) rotate(-10deg);
  }
  10.6854% {
    -webkit-transform: translate(665px, -55.7px) rotate(-15deg);
  }
  14.0988% {
    -webkit-transform: translate(630px, -66.9px) rotate(-20deg);
  }
  17.6009% {
    -webkit-transform: translate(595px, -77.7px) rotate(-25deg);
  }
  21.1960% {
    -webkit-transform: translate(560px, -97.8px) rotate(-30deg);
  }
  24.8826% {
    -webkit-transform: translate(525px, -115.4px) rotate(-35deg);
  }
  28.6540% {
    -webkit-transform: translate(490px, -130.2px) rotate(-40deg);
  }
  36.4038% {
    -webkit-transform: translate(455px, -141.5px) rotate(-45deg);
  }
  44.3313% {
    -webkit-transform: translate(420px, -148.9px) rotate(-50deg);
  }
  52.2881% {
    -webkit-transform: translate(385px, -151.8px) rotate(-55deg);
  }
  60.1068% {
    -webkit-transform: translate(350px, -149.8px) rotate(-60deg);
  }
  67.6098% {
    -webkit-transform: translate(315px, -142.4px) rotate(-65deg);
  }
  74.6151% {
    -webkit-transform: translate(280px, -129px) rotate(-70deg);
  }
  80.9440% {
    -webkit-transform: translate(245px, -119.9px) rotate(-75deg);
  }
  86.4317% {
    -webkit-transform: translate(210px, -109.1px) rotate(-80deg);
  }
  90.9520% {
    -webkit-transform: translate(175px, -96.6px) rotate(-85deg);
  }
  92.8408% {
    -webkit-transform: translate(140px, -82.3px) rotate(-90deg);
  }
  94.5004% {
    -webkit-transform: translate(105px, -66.2px) rotate(-95deg);
  }
  95.9806% {
    -webkit-transform: translate(70px, -48.1px) rotate(-100deg);
  }
  97.7196% {
    -webkit-transform: translate(35px, -28px) rotate(-100deg);
  }
  100.0000% {
    -webkit-transform: translate(0px, 0px) rotate(-100deg);
  }
}

@keyframes LeftToRight {
  0.0000% {
    -webkit-transform: translate(-770px, -11px) rotate(0deg);
  }
  4.0681% {
    -webkit-transform: translate(-735px, -29.3px) rotate(5deg);
  }
  7.3487% {
    -webkit-transform: translate(-700px, -44.1px) rotate(10deg);
  }
  10.6854% {
    -webkit-transform: translate(-665px, -55.7px) rotate(15deg);
  }
  14.0988% {
    -webkit-transform: translate(-630px, -66.9px) rotate(20deg);
  }
  17.6009% {
    -webkit-transform: translate(-595px, -77.7px) rotate(25deg);
  }
  21.1960% {
    -webkit-transform: translate(-560px, -97.8px) rotate(30deg);
  }
  24.8826% {
    -webkit-transform: translate(-525px, -115.4px) rotate(35deg);
  }
  28.6540% {
    -webkit-transform: translate(-490px, -130.2px) rotate(40deg);
  }
  36.4038% {
    -webkit-transform: translate(-455px, -141.5px) rotate(45deg);
  }
  44.3313% {
    -webkit-transform: translate(-420px, -148.9px) rotate(50deg);
  }
  52.2881% {
    -webkit-transform: translate(-385px, -151.8px) rotate(55deg);
  }
  60.1068% {
    -webkit-transform: translate(-350px, -149.8px) rotate(60deg);
  }
  67.6098% {
    -webkit-transform: translate(-315px, -142.4px) rotate(65deg);
  }
  74.6151% {
    -webkit-transform: translate(-280px, -129px) rotate(70deg);
  }
  80.9440% {
    -webkit-transform: translate(-245px, -119.9px) rotate(75deg);
  }
  86.4317% {
    -webkit-transform: translate(-210px, -109.1px) rotate(80deg);
  }
  90.9520% {
    -webkit-transform: translate(-175px, -96.6px) rotate(85deg);
  }
  92.8408% {
    -webkit-transform: translate(-140px, -82.3px) rotate(90deg);
  }
  94.5004% {
    -webkit-transform: translate(-105px, -66.2px) rotate(95deg);
  }
  95.9806% {
    -webkit-transform: translate(-70px, -48.1px) rotate(100deg);
  }
  97.7196% {
    -webkit-transform: translate(-35px, -28px) rotate(100deg);
  }
  100.0000% {
    -webkit-transform: translate(0px, 0px) rotate(100deg);
  }
}

@keyframes BottomToTop {
  0.0000% {
    -webkit-transform: translate(-11px, 552px) rotate(-100deg);
  }
  4.0681% {
    -webkit-transform: translate(-29.3px, 549px) rotate(-95deg);
  }
  7.3487% {
    -webkit-transform: translate(-44.1px, 547.8px) rotate(-90deg);
  }
  10.6854% {
    -webkit-transform: translate(-55.7px, 542.6px) rotate(-85deg);
  }
  14.0988% {
    -webkit-transform: translate(-66.9px, 533.8px) rotate(-80deg);
  }
  17.6009% {
    -webkit-transform: translate(-77.7px, 521.8px) rotate(-75deg);
  }
  21.1960% {
    -webkit-transform: translate(-97.8px, 488.6px) rotate(-70deg);
  }
  24.8826% {
    -webkit-transform: translate(-115.4px, 445px) rotate(-65deg);
  }
  28.6540% {
    -webkit-transform: translate(-130.2px, 422.8px) rotate(-60deg);
  }
  36.4038% {
    -webkit-transform: translate(-141.5px, 403.9px) rotate(-55deg);
  }
  44.3313% {
    -webkit-transform: translate(-148.9px, 370px) rotate(-50deg);
  }
  52.2881% {
    -webkit-transform: translate(-151.8px, 303px) rotate(-45deg);
  }
  60.1068% {
    -webkit-transform: translate(-149.8px, 267.9px) rotate(-40deg);
  }
  67.6098% {
    -webkit-transform: translate(-142.4px, 234.3px) rotate(-35deg);
  }
  74.6151% {
    -webkit-transform: translate(-129px, 202.3px) rotate(-30deg);
  }
  80.9440% {
    -webkit-transform: translate(-119.9px, 171.2px) rotate(-25deg);
  }
  86.4317% {
    -webkit-transform: translate(-109.1px, 141.5px) rotate(-20deg);
  }
  90.9520% {
    -webkit-transform: translate(-96.6px, 113.4px) rotate(-15deg);
  }
  92.8408% {
    -webkit-transform: translate(-82.3px, 87px) rotate(-10deg);
  }
  94.5004% {
    -webkit-transform: translate(-66.2px, 62.6px) rotate(-5deg);
  }
  95.9806% {
    -webkit-transform: translate(-48.1px, 40.5px) rotate(0deg);
  }
  97.7196% {
    -webkit-transform: translate(-28px, 20.8px) rotate(0deg);
  }
  100.0000% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes TopToBottom {
  0.0000% {
    -webkit-transform: translate(-11px, -552px) rotate(-100deg);
  }
  4.0681% {
    -webkit-transform: translate(-29.3px, -549px) rotate(-105deg);
  }
  7.3487% {
    -webkit-transform: translate(-44.1px, -547.8px) rotate(-110deg);
  }
  10.6854% {
    -webkit-transform: translate(-55.7px, -542.6px) rotate(-115deg);
  }
  14.0988% {
    -webkit-transform: translate(-66.9px, -533.8px) rotate(-120deg);
  }
  17.6009% {
    -webkit-transform: translate(-77.7px, -521.8px) rotate(-125deg);
  }
  21.1960% {
    -webkit-transform: translate(-97.8px, -488.6px) rotate(-130deg);
  }
  24.8826% {
    -webkit-transform: translate(-115.4px, -445px) rotate(-135deg);
  }
  28.6540% {
    -webkit-transform: translate(-130.2px, -422.8px) rotate(-140deg);
  }
  36.4038% {
    -webkit-transform: translate(-141.5px, -403.9px) rotate(-145deg);
  }
  44.3313% {
    -webkit-transform: translate(-148.9px, -370px) rotate(-150deg);
  }
  52.2881% {
    -webkit-transform: translate(-151.8px, -303px) rotate(-155deg);
  }
  60.1068% {
    -webkit-transform: translate(-149.8px, -267.9px) rotate(-160deg);
  }
  67.6098% {
    -webkit-transform: translate(-142.4px, -234.3px) rotate(-165deg);
  }
  74.6151% {
    -webkit-transform: translate(-129px, -202.3px) rotate(-170deg);
  }
  80.9440% {
    -webkit-transform: translate(-119.9px, -171.2px) rotate(-175deg);
  }
  86.4317% {
    -webkit-transform: translate(-109.1px, -141.5px) rotate(-180deg);
  }
  90.9520% {
    -webkit-transform: translate(-96.6px, -113.4px) rotate(-185deg);
  }
  92.8408% {
    -webkit-transform: translate(-82.3px, -87px) rotate(-190deg);
  }
  94.5004% {
    -webkit-transform: translate(-66.2px, -62.6px) rotate(-195deg);
  }
  95.9806% {
    -webkit-transform: translate(-48.1px, -40.5px) rotate(-200deg);
  }
  97.7196% {
    -webkit-transform: translate(-28px, -20.8px) rotate(-200deg);
  }
  100.0000% {
    -webkit-transform: translate(0px, 0px) rotate(-200deg);
  }
}

@keyframes size {
  from {
    width: 100%;
  }

  50% {
    width: 500%;
  }

  to {
    width: 100%;
  }
}

.MissileOrcaAI {
  z-index: 9999;
  position: absolute;
  //animation: xa 1.5s linear infinite alternate,
  //  y 1.5s infinite cubic-bezier(0, 500, 1, 500);
  animation: LeftToRight 1.5s linear, size 1.5s linear;

  @media only screen and (max-width: $mobile-breakpoint) {
    animation: BottomToTop 1.5s linear, size 1.5s linear;
  }
}

.MissileOrcaPlayer {
  z-index: 9999;
  position: absolute;
  //animation: xa 1.5s linear infinite alternate,
  //  y 1.5s infinite cubic-bezier(0, 500, 1, 500);
  animation: RightToLeft 1.5s linear, size 1.5s linear;

  @media only screen and (max-width: $mobile-breakpoint) {
    animation: TopToBottom 1.5s linear, size 1.5s linear;
  }
}

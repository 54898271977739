$mobile-breakpoint: 900px;

.ShipCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-top: 20px;
  }
}

.ShipCompanyText {
  font-size: 36px;
  margin-bottom: 24px;
  font-weight: 800;
  text-transform: uppercase;
  color: transparent;
  background: linear-gradient(to right, #f8c626, #fff8c9, #c08f2e, #fff8c9);
  background-clip: text;

  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.BannerContainer {
  width: 250px;
  position: relative;

  @media only screen and (max-width: $mobile-breakpoint) {
    width: 125px;
  }
}

.Banner {
  max-width: 100%;
}

.Avatar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 140px;
  width: 140px;

  @media only screen and (max-width: $mobile-breakpoint) {
    height: 50px;
    width: 50px;
  }
}

.ShipCaptain {
  margin-top: 16px;
  margin-bottom: 0;
}

.UnselectButton {
  position: absolute;
  top: -10px;
  right: -40px;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  border: 0;
  padding: 0;
  &:hover {
    background-color: #282828;
  }
}

.IconButtonIcon {
  width: 100%;
  height: 100%;
}

.ModalSubtext {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}

.ModalButton {
  margin-left: 16px;
  color: black;
  background-color: #d3d3d3;
  &:hover {
    background-color: #a9a9a9;
  }
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

$mobile-breakpoint: 900px;

.ScreenContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.IntroScreenVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.WTFButton {
  position: absolute;
  top: 32px;
  right: 2.5%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    #f8c626,
    #fff8c9,
    #c08f2e,
    #fff8c9
  );
  &:hover {
    background-color: #282828;
  }
}

.IntroScreenContent {
  position: absolute;
  width: 100%;
  height: 100%;
}

.IntroScreenLineHolder {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.IntroScreenTagline {
  margin-top: 100px;
  margin-bottom: 32px;
}

.IntroScreenTitle {
  margin-top: 0;
  margin-bottom: 32px;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-top: 10%;
  }
}

.GoldGradientText {
  color: transparent;
  background: linear-gradient(to right, #f8c626, #fff8c9, #c08f2e, #fff8c9);
  background-clip: text;
}

.StartSinkingButton {
  background-color: #4caf50;
  &:hover {
    background-color: #3d8b40;
  }
}

.IconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  padding: 5px;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid;
  &:not(:disabled) {
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      #f8c626,
      #fff8c9,
      #c08f2e,
      #fff8c9
    );
    &:hover {
      background-color: #282828;
    }
  }
}
